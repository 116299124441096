import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = ({data}) => (
  <Layout>
    <SEO title="Privacy" />
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <h4>Privacy Policy</h4>
        </div>
      </div>
      <div className="row">
        <div className="column mt-4">
          <div dangerouslySetInnerHTML={{ __html: JSON.parse( data.restApiApiV1Privacy.internal.content ).data.privacy_data  }} />
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query PrivacyQuery {
    restApiApiV1Privacy {
      internal {
        content
      }
    }
  }
`

export default PrivacyPage
